import {
    Component,
    OnInit,
    OnDestroy,
    Input
  } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { Claim } from '@app/claims/models/claim.model'

import * as fromStore from '@app/claims/store';
import * as fromCoreStore from '@app/core/store';
import { CloseModal } from '@app/modals/store/actions/layout.actions';

@Component({
    selector: 'app-claim-adjusters-notes',
    templateUrl: './claim-adjusters-notes.component.html',
    styleUrls: ['claim-adjusters-notes.component.scss']
})

export class ClaimAdjustersNotes implements OnInit, OnDestroy {

    destroyed$ = new Subject<boolean>();

    @Input()
    set props (v) {
      if (v) {
        this.adjustersNotes = []
        this.allNotes = []
        this.claimId = v?.props?.claimId || ''
        this.adjustersNotes = v?.props?.notes.filter(i => i?.vehicleId == v?.props?.vehicle?._id) || []
        this.allNotes = v?.props?.notes
        let localInspactions = v.props.vehicle.inspactions
        this.vehicle = v.props.vehicle
        let localPickupInspection = []
        let localDeliveryInspection = []
        this.pickupInspection = []
        this.deliveryInspection = []
        for (let n of localInspactions) {
          if (n.typeInspaction == 'pre') {
            localPickupInspection = [...localPickupInspection, ...n.photos]
          }
          if (n.typeInspaction == 'post') {
            localDeliveryInspection = [...localDeliveryInspection, ...n.photos]
          }
        }
        this.pickupInspection = [...this.pickupInspection, ...localPickupInspection ]
        this.deliveryInspection = [...this.deliveryInspection, ...localDeliveryInspection ]
      }
    }

    allNotes
    pickupInspection = []
    deliveryInspection = []
    claimId
    adjustersNotes = []
    vehicle

    constructor(
      private store: Store<fromStore.State>, 
      private coreStore: Store<fromCoreStore.State>
    ) {}

    ngOnInit() {
    }

    close() {
      this.coreStore.dispatch(new CloseModal());
    }

 
    changeNotes(value) {
      let adjustersNotesIds = this.allNotes.map(i => i = i.vehicleId)
      let incl = adjustersNotesIds.includes(this.vehicle?._id)
      let notes = this.allNotes

      if (incl) {
        for (let note of notes) {
          if (note.vehicleId == this.vehicle?._id) {
            note.content = value;
          }
        }
      } else {
        const newNote = {
          vehicleId: this.vehicle?._id,
          content: value
        };
        notes = [...this.allNotes, newNote]
      }
    
      let adjusters = {
        adjusters: {
          notes
        }
      };
    
      this.store.dispatch(
        new fromStore.PatchClaim({
          claim: {
            id: this.claimId,
            changes: adjusters
          }
        })
      );
    }

    ngOnDestroy() {
      this.allNotes = []
      this.adjustersNotes = []
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
}