<div class="users-container">
  @for(user of onlineUsers; track user._id){
    <div class="user-container online">
      <div class="user-icon-container">
        <i class="fas fa-user"></i>
      </div>
      <div class="flex items-center justify-between w-full">
        <span class="text-black text-lg acc-name font-semibold">{{ user.name }}</span>
        <span class="text-green-400 status text-base">Online</span>
      </div>
    </div>
  }
  @for(user of afkUsers; track user._id){
    <div class="user-container afk">
      <div class="user-icon-container">
        <i class="fas fa-user"></i>
      </div>
      <div  class="flex items-center justify-between w-full">
        <div class="flex flex-col">
          <span class="text-black text-lg acc-name font-semibold">{{ user.name }}</span>
          <small *ngIf='user._lastActive' class="text-xs text-black">Last active: {{ user._lastActive | date:'hh:mm aaa' }}</small>
        </div>
        <span class="text-yellow-400 status text-base">Away</span>
      </div>

    </div>
  }
  @for(user of offlineUsers; track user._id){
    <div class="user-container offline">
      <div class="user-icon-container">
        <i class="fas fa-user"></i>
      </div>
      <div  class="flex items-center justify-between w-full">
        <span class="text-black text-lg acc-name font-semibold">{{ user.name }}</span>
        <span class="text-red-400 status text-base">Offline</span>
      </div>
    </div>
  }
</div>
