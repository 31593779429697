<div class="flex justify-between items-center modal-header-nearby bg-gray-100 rounded-t-lg">
    <p class="text-gray-900 font-poppins">Shops Nearby</p>
    <button class="" type="button" (click)="close()">
      <span aria-hidden="true">
        <i class="fas fa-close text-gray-400 text-lg"></i>
      </span>
    </button>
</div>
<div class="nearby-container bg-bg-gray-100 rounded-b-lg">
    <div class="modal-body-nearby bg-bg-gray-100">
        <div class="nearby_wr">
          <div class="nearby-filter">
            <app-input
              [value]="filters?.name"
              [idInfo]="'nearby-search'"
              [debounceEnable]="true"
              [disableValidation]="true"
              [hightLightIfNotEmpty]="true"
              [isFilterInput]="true"
              [options]="{iconColor: '#1E429F', padding_left_input: true}"
              [icon]="'fa-solid fa-magnifying-glass'"
              [label]="'Search Shop'"
              (onChange)="setFilter('company', $event)"
            ></app-input>
            <app-multi-select 
              [idInfo]="'nearby-type'"
              [visibleCount]="1"
              [data]="type" 
              [value]="filters?.type"
              (onSave)="setFilter('type', $event)">
            </app-multi-select>
          </div>
          <div class="nearby_items">
            @for (account of accounts; track $index) {
              <div class="nearby_item bg-white">
                <div class="nearby_title">
                  <div class="flex items-center">
                    <span class="name"><span class="mr-2">{{account?.num}}.</span>{{account?.name}}</span>
                  </div>
                  <button (click)="setPosition(account?.location)">
                    <i class="fa-solid fa-diamond-turn-right"></i>
                  </button>
                </div>
                <div class="nearby_body flex flex-col">
                  <span class="nearby_data">Truck repair shop</span>
                  <span class="nearby_address">{{account?.fullAddress}}</span>
                  <div class="nearby_raiting">
                    <app-rating 
                      [rating]="account?.meta?.account?.rating"
                      [isReadOnly]="true"
                    ></app-rating>
                    <span 
                      *ngIf="account?.sharedMeta?.account?.accessHours && account?.sharedMeta?.account?.accessHours !== ''" 
                      class="nearby_hourse"
                    >{{account?.sharedMeta?.account?.accessHours}}</span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <div class="nearby_map">
          <google-map        
              width="100%"
              height="100%"
              #map
              [zoom]="7"
              (idle)='onMapReady()'
              [center]="{lat:location?.lat, lng:location?.lng}" 
            >
            <map-marker
              #somemarker="mapMarker"
              [position]="{lat:vehicleLocation?.lat, lng:vehicleLocation?.lng}"
              [icon]="{url: 'assets/map_pins/general_map_pins/car.png'}"
            >
            </map-marker>
            @for(account of accounts; track account?._id){
              <template>
                <map-marker
                  #somemarker="mapMarker"
                  [position]="{lat: account?.location?.lat, lng:account?.location?.lng}"
                  [label]="{
                    color: '#fff',  
                    fontSize: '12.5px', 
                    text: account?.num.toString()
                  }" 
                  [options]="{
                    zIndex: account?.num,
                    title:account?.companyName
                  }"
                  (mapClick)='openAccountInfo(account, somemarker);'>
                </map-marker>
                <map-info-window (closeclick)="closeClick()">
                  <div>
                    <div class="flex text-center text-base font-medium items-center mb-2">
                      <i class="fa-solid fa-user mr-2 text-xs"></i>
                      <span>{{selectedCompany?.name}}</span>
                    </div>
                    @if (selectedCompany?.phones?.length) {
                      @for(phone of selectedCompany?.phones; track $index){
                        <div class="flex text-center text-base font-medium items-center mb-2">
                          <i class="fa-solid fa-phone mr-2 text-xs"></i>
                          <span>({{phone.value.slice(0, 3)}}) {{phone.value.slice(3, 6)}}-{{phone.value.slice(6)}}</span>
                        </div>
                      }
                      
                    }
                    @if (selectedCompany?.emails?.length) {
                      @for(email of selectedCompany?.emails; track $index){
                        <div class="flex text-center text-base font-medium items-center mb-2">
                          <i class="fa-solid fa-plane mr-2 text-xs"></i>
                          <span>{{email.value}}</span>
                        </div>
                      }
                    }
                    <div class="flex text-center text-base font-medium items-center">
                      <i class="fa-solid fa-location-dot mr-2 text-xs"></i>
                      <span>{{selectedCompany?.fullAddress  ? selectedCompany.fullAddress : 'Unable to obtain location'}}</span>
                    </div>
                  </div>
                </map-info-window>
              </template>
            }
          </google-map>
        </div>
    </div>
    <div class="nearby-modal-footer bg-bg-gray-100 rounded-b-lg text-right">
      <button class="close_btn" type="button" (click)="close()">
        Close
      </button>
    </div>
</div>








