import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-company-message',
  templateUrl: './company-message.component.html',
  styleUrls: ['./company-message.component.scss']
})
export class CompanyMessageComponent implements OnInit {
  @Input()
  author
  @Input()
  subject
  @Input()
  text
  @Output()
  close = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
