import { animate, state, style, transition, trigger } from '@angular/animations'
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Observable, of, Subject } from 'rxjs'

import * as fromCoreStore from '@app/core/store'
import * as fromSharedStoreAction from '@app/shared/store/actions'
import * as fromUserStore from '@app/users/store'
// import * as fromOrderStore from '@app/quotes/store'

import * as fromModalStoreActions from '@app/core/store/actions'
import * as fromModalStoreSelectors from '@app/core/store/selectors'

import { Location } from '@angular/common'
import { NavigationStart, Router } from '@angular/router'
import { GetNewVersionNotification } from '@app/core/store'
import { User } from '@app/shared/models'
import { DieselService, ScheduleService } from '@app/shared/services'
import { Store } from '@ngrx/store'
import { catchError, distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators'
import { PushNotifications } from '../services/push-notifications.service'
import { NgxPopperjsTriggers, NgxPopperjsPlacements } from 'ngx-popperjs'
declare var require: any

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit, OnDestroy {

  NgxPopperjsTriggers = NgxPopperjsTriggers
  NgxPopperjsPlacements = NgxPopperjsPlacements


  menuState = 'in'
  @Output()
  onActiveMenu = new EventEmitter<boolean>()
  @Output()
  onNavMobileClose = new EventEmitter<void>()

  @Input()
  options: { isActiveSidebar?: boolean, mobile?: boolean } = { isActiveSidebar: false, mobile: false }

  darkThemeEnabled: boolean = false;

  openNavigation = false

  currentUser$: Observable<User>
  userSettings$: Observable<any>
  loggedIn$: Observable<boolean>
  destroyed$ = new Subject<boolean>()
  isLoadedAccounts$: Observable<boolean>
  statusSidebar = false
  @Input()
  isSubscribed = false
  onCustomerPortal = false
  widthOutput = null;
  locationEvent$
  contact
  roles$
  currentRoute:string
  schedulesCount = 0
  savedTheme = localStorage.getItem('theme');

  fullView: boolean = false
  constructor(
    public dieselService: DieselService,
    private _sanitizer: DomSanitizer,
    private _PushNotifications: PushNotifications,
    private store: Store,
    private router: Router,
    private location: Location,
    private scheduleService: ScheduleService,
    private cdr: ChangeDetectorRef
  ) {
    this.router.events.subscribe(
      eventItem => {
        if(eventItem instanceof NavigationStart){
          this.currentRoute = this.location.path()
          const currentRouteItems = this.currentRoute.split('/').filter(t => !!t).map(item => {
            return item?.indexOf('?') !== -1 ? item?.substring(0, item?.indexOf('?')) : item
          })
          const eventItemItems = eventItem.url.split('/').filter(t => !!t).map(item => {
            return item?.indexOf('?') !== -1 ? item?.substring(0, item?.indexOf('?')) : item
          })
          if(eventItemItems.length && currentRouteItems.length && eventItemItems?.[0] !== currentRouteItems?.[0]){
            this.store.dispatch(new fromSharedStoreAction.CleanStore())
          }
        }
      }
    )
   }

  appVersion = require('../../../../package.json').version

  isLoggedIn = false
  userTheme = localStorage.getItem('color-theme')

  ngOnInit() {
    this.store
    .select(fromCoreStore.selectScheduleFilter)
    .pipe(
      takeUntil(this.destroyed$),
      take(1),
    )
    .subscribe(filters => {
      let f = {
        ...filters,
        status: 'PENDING',
        subType: 'Drivers',
        onlyTabs: true
      };
  
      if (f?.rangeType) delete f.rangeType;
      if (f?.driver) {
        f['driverId'] = f.driver._id;
        delete f.driver;
      }
      if (f?.unit) {
        f['unitId'] = f.unit._id;
        delete f.unit;
      }
      if (f?.dispatcher) {
        f['dispatchId'] = f.dispatcher._id;
        delete f.dispatcher;
      }
      
      this.store.dispatch(new fromCoreStore.GetSchedules(f));
    })

    this.store.select(fromCoreStore.selectSchedulesTabs).pipe(
      takeUntil(this.destroyed$),
      map(tabs => {
        for (let s of tabs?.statuses) {
          if (s?.name === 'PENDING') {
            this.schedulesCount = s?.count
            this.cdr.detectChanges()
          }
        }
      })
    ).subscribe()

    this.loggedIn$ = this.store.select(fromUserStore.getLoggedIn).pipe(
      takeUntil(this.destroyed$),
      tap(loggedIn => {
        this.isLoggedIn = loggedIn;
      })
    )
    this.currentUser$ = this.store.select(fromUserStore.getUser)
    this.userSettings$ = this.store.select(fromUserStore.getUserSettings)
    this.isLoadedAccounts$ = this.store.select(fromCoreStore.getContactLoaded)
    this.isLoadedAccounts$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe()
    this.store.dispatch(new GetNewVersionNotification({ version: this.appVersion }))
    this.roles$ = this.store.select(fromModalStoreSelectors.selectPermissionRoleEntities).pipe(
      tap(r => {
        if(this.isLoggedIn){
          !Object.keys(r)?.length && this.store.dispatch(new fromModalStoreActions.GetPermissionRoles())
        }
      })
    )

    window.addEventListener("resize", this.resizeListener);
  }

  resizeDropdown() {
    this.resizeListener()
  }

  navToggle() {
    this.openNavigation = !this.openNavigation
  }

  onCloseNavigation() {
    this.openNavigation = false
    this.onNavMobileClose.emit()
  }

  onToggleTheme() {
    this.darkThemeEnabled = !this.darkThemeEnabled
  }

  resizeListener() {
    this.widthOutput = document.querySelector(".dropdown-menu");
    if (this.widthOutput) {
      if (window.innerWidth < 1185) {
        this.widthOutput.style['transform'] = `translateX(${+window.innerWidth-145}px)`
      } else {
        this.widthOutput.style['transform'] = '';
      }
    }
  }
  autocompleListFormatter = (data: any): SafeHtml => {
    const html = `<span>${data.toponymName}, ${data.adminCode1} </span>`
    return this._sanitizer.bypassSecurityTrustHtml(html)
  }

  async togglePushNotifications() {
    this._PushNotifications.toggleSubscription({ isPushEnabled: this.isSubscribed })
    this.isSubscribed = !this.isSubscribed

    // this.store.dispatch(new fromUserStore.TogglePushNotify());
  }

  toggleMenu() {
    // 1-line if statement that toggles the value:
    this.menuState = this.menuState === 'out' ? 'in' : 'out'
    this.dieselService.menuState = this.menuState
    console.log('TOGGLE MENU')
  }

  logout() {
    window.removeEventListener("resize", this.resizeListener);
    this.widthOutput = null;
    this.store.dispatch(new fromUserStore.Logout())
  }

  toggleTheme(){
    if(document.documentElement.classList.contains("dark")){
      this.userTheme = 'light'
      localStorage.setItem('color-theme', 'light')
      document.documentElement.classList.remove("dark")
    }else{
      this.userTheme = 'dark'
      localStorage.setItem('color-theme', 'dark')
      document.documentElement.classList.add("dark")
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
