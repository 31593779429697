import { NgModule } from '@angular/core'

import { ComponentsModule } from '@app/sidebar/components'

import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { reducers, effects } from '@app/sidebar/store'
import { SideBarMenuComponent } from './containers'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from '@app/shared/shared.module'
import { provideClientHydration } from '@angular/platform-browser'

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        StoreModule.forFeature('sidebar', reducers),
        EffectsModule.forFeature(effects),
        ComponentsModule,
        SharedModule
    ],
    declarations: [SideBarMenuComponent],
    exports: [SideBarMenuComponent],
    providers: [provideClientHydration()]
})
export class SidebarModule {}
