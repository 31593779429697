<div class="flex justify-between modal-body-header-account bg-white rounded-t-lg items-center">
  <h4 class="text-gray-900 text-lg font-poppins">Create New Account</h4>
  <button class="" type="button" id="create-new-account-close-modal" (click)="close()"
    aria-label="Close">
    <span aria-hidden="true">
      <i class="fas fa-close text-gray-500 text-lg"></i>
    </span>
  </button>
</div>
<div class="modal-body modal-body-account">
  <form #f="ngForm" (onSubmit)="onSubmit()" class="m-form m-form--fit m-form--label-align-right" id="m_form_1">
    <div class="">
      <div [formGroup]="formAccount">
        <div>
          <app-select
            [idInfo]="'select-assignee'"
            (onSave)="formAccount.get('assignee').setValue($event)"
            [value]="formAccount.get('assignee').value"
            [list]="users$ | async"
            [keyName]="{key:'_id', name:'name'}"
            [options]="{users:true}"
            [label]="'Account Holder'"
            [placeholder]="'Select Account Holder'"
          ></app-select>
        <div *ngIf="assigneeAccount.invalid && (assigneeAccount.dirty||assigneeAccount.touched)" class="text-red-700 font-poppins text-xs mt-1 ml-2">
          Please select Account Holder!
        </div>
        </div>
        <div class="account-form_input">
          <div>
            <app-select
              [idInfo]="'select-type'"
              (onSave)="formAccount.get('type').setValue($event)"
              [value]="formAccount.get('type').value"
              [list]="types"
              [label]="'TYPE'"
              [placeholder]="'Select Type'"
            ></app-select>
          </div>
          <div *ngIf="typeAccount.invalid && (typeAccount.dirty||typeAccount.touched)" class="text-red-700 font-poppins text-xs mt-1 ml-2">
            Please select type!
          </div>
        </div>
      </div>
      <div [formGroup]="formMain">
        <div class="account-form_input">
          <app-input
            [idInfo]="'account_name'"
            [value]="formMain.get('name').value"
            [type]="'text'"
            [label]="'Name'"
            [options]="{bg_white: true}"
            (onSave)="formMain.get('name').setValue($event)"
          ></app-input>
          <div *ngIf="nameMain.invalid && (nameMain.dirty||nameMain.touched)" class="text-red-700 font-poppins text-xs mt-1 ml-2">
            Please input name, it's required!
          </div>
        </div>
        <div class="account-form_input">
          <app-input
            [idInfo]="'account_phone'"
            [value]="formMain.get('phone').value"
            [type]="'phone'"
            [label]="'Phone'"
            [options]="{bg_white: true, padding_left_input: false}"
            (onSave)="formMain.get('phone').setValue($event)"
          ></app-input>
        </div>
        <div class="relative account-form_input">
          <app-input
            [idInfo]="'addressLine1'"
            [value]="formMain.get('addressLine1').value"
            [type]="'text'"
            [label]="'Address'"
            [options]="{bg_white: true}"
            (onSave)="formMain.get('addressLine1').setValue($event)"
          ></app-input>
        </div>
        <div class="flex justify-between account-form_input">
          <div class="width_49">
            <app-input
              [idInfo]="'city'"
              [value]="formMain.get('city').value"
              [type]="'text'"
              [label]="'City'"
              [options]="{bg_white: true}"
              (onSave)="formMain.get('city').setValue($event)"
            ></app-input>
          </div>
          <div class="width_49">
            <app-select
              [idInfo]="'select-state'"
              (onSave)="formMain.get('state').setValue($event)"
              [list]="states"
              [label]="'STATE'"
              [placeholder]="'State'"
              [value]="formMain.get('state').value"
            ></app-select>
          </div>
        </div>
      </div>
      <div class="account-form_input" [formGroup]="formPrimary">
        <div>
          <app-input
            [idInfo]="'poc-name'"
            [value]="formPrimary.get('name').value"
            [type]="'text'"
            [label]="'Name'"
            [options]="{bg_white: true}"
            (onSave)="formPrimary.get('name').setValue($event)"
          ></app-input>
          <div *ngIf="namePrimary.invalid && (namePrimary.dirty||namePrimary.touched)" class="text-red-700 font-poppins text-xs mt-1 ml-2">
            Please enter name, it's required!
          </div>
        </div>
        <div class="account-form_input">
          <app-input
            [idInfo]="'title'"
            [value]="formPrimary.get('title').value"
            [type]="'text'"
            [label]="'Title'"
            [options]="{bg_white: true}"
            (onSave)="formPrimary.get('title').setValue($event)"
          ></app-input>
        </div>
        <div class="account-form_input">
          <app-input
            [idInfo]="'add_phone_poc'"
            [value]="formPrimary.get('phone').value"
            [type]="'phone'"
            [label]="'Phone'"
            [options]="{bg_white: true, padding_left_input: false}"
            (onSave)="formPrimary.get('phone').setValue($event)"
          ></app-input>
        </div>
        <div class="relative account-form_input">
          <app-input
            [idInfo]="'email'"
            [value]="formPrimary.get('email').value"
            [type]="'email'"
            [label]="'Email'"
            [options]="{bg_white: true, padding_left_input: false}"
            (onSave)="formPrimary.get('email').setValue($event)"
          ></app-input>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="bg-white rounded-lg drops">
  <app-dropzone
      [idInfo]="'add-avatar'"
      [createAccount]="true"
      [resource]="FOLLOW_UP_RESOURCE.ACCOUNT_LOGO"
      [resourceId]="contact?._id"
      [options]="{ disableMeta: true, public: true }"
      (success)="uploadSuccess($event)"
      (remove)="removeLogo()"
      (error)="uploadError($event)"
  ></app-dropzone>
  <div class="onSubmit-wr">
    <button (click)="onSubmit()" id="onSubmit" class="bg-blue-700 text-white">
      Save Account
    </button>
  </div> 
</div>
