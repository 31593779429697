<div *ngIf="(events$ | async) as events">
  @for( event of events; let last = $last; track event._id){
    <div [ngClass]="{ 'mb-1': !last }" class="event-item" (click)="markAsRead(event)" [ngStyle]="!event?.isRead && {'background-color': 'rgb(232, 240, 254)'}" >
      <div class="flex items-center justify-between">
        <div class="flex items-center action-buttons">
          <a
            *ngIf="event.isRead"
            [routerLink]="[]"
            class="cursor-pointer mr-2"
            title="Mark As Unread"
            (click)="markAsUnread(event)"
          >
            <i class="fa-solid fa-eye-low-vision text-blue-500 text-base"></i>
          </a>
          <a
            *ngIf="event.resourceId"
            [routerLink]="[]"
            title="Open Resource"
            (click)="redirect(event)"
          >
            <i class="fas fa-external-link-alt text-blue-500 text-base"></i>
          </a>
        </div>

        <app-btn-confirm  (onConfirm)="deleteEvent(event)"></app-btn-confirm>

      </div>

      <div class="text-black text-base event-block" [innerHTML]="event?._contentHTML || event?.content"></div>

      <span class="text-black text-sm" *ngIf="!event.content || event.followUpType === 'Note'"
      (click)=" event.notifExpand
          ? (event.notifExpand = 0)
          : (event.notifExpand = 1)
      ">
        {{ event?.followUpType }}
      </span>

      <div class="flex items-center">
        <div class="text-black text-base font-semibold">
          {{ event?.followUp | date: 'short' }}
        </div>


        <span  class="badge-black text-xs ml-1"
          title="{{ event?.creator?.name }}">{{ event?.creator?.name | shrinkName }}</span>
      </div>
    </div>
  }
</div>
