<div class="modal-header"  style="padding-bottom: 5px;">
    <h3 class="modal-title">New Version Available</h3>
    <button class="btn btn-link" type="button" (click)="closeModal()" aria-label="Close"><span aria-hidden="true">
        <i class="fas fa-times"></i>
    </span></button>
</div>
<div class="modal-body" style="padding-top: 5px;">
    <p>Please reload page to load New App Version.</p> 
    <button class="btn btn-outline-primary" type="button" (click)='pageReload()'><i class="fas fa-cloud-download-alt"></i> Load New Version</button>
    <button class="btn btn-link" type="button" (click)='pageReload(true)'> Vew Release Notes</button>
</div>
