import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule, CoreComponentsModule } from '@app/shared';

import { ToastListComponent } from '@app/core/components/toast-list.component';
import { ToastItemComponent } from '@app/core/components/toast-item.component';
import { CompanyMessageComponent } from './company-message/company-message.component'

export const COMPONENTS = [
  ToastListComponent,
  ToastItemComponent,
  CompanyMessageComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CoreComponentsModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ComponentsModule {}
