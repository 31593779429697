import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { DieselService } from '@app/shared';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(0, 0, 0)'
        })
      ),
      state(
        'out',
        style({
          transform: 'translate3d(100%, 0, 0)'
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class MenuComponent implements OnInit {
  menuState: string;
  constructor(public dieselService: DieselService) {}

  toggleMenu() {
    // 1-line if statement that toggles the value:
    this.menuState = this.dieselService.menuState;
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    this.dieselService.menuState = this.menuState;
  }

  ngOnInit() {}
}
