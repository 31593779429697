import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectionStrategy, HostListener, ChangeDetectorRef } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { UserActivityService } from '@app/shared/services/user-activity.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCoreStore from '@app/core/store';
import * as fromAuthStore from '@app/users/store';
import * as fromModalStore from '@app/modals/store';
import { GetNewVersionNotification } from '@app/core/store';
import { initFlowbite } from "flowbite";
import { SidebarService } from './services/sidebar.service'
import { PushNotifications } from './services/push-notifications.service';
declare var require: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(0, 0, 0)'
        })
      ),
      state(
        'out',
        style({
          transform: 'translate3d(100%, 0, 0)'
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('mainContainer', {static: false })
  private mainContainer;

  title = 'app works!';
  message;
  menuState = 'out';
  appVersion = require('../../../package.json').version;
  activateMenu = false;
  routeUrl$: Observable<any>;
  currentUser$: Observable<any>;
  showNavigation = true
  isPublic = false;
  showSidebarRight = true
  showPreloader = true
  loggedIn = false
  windowWidth: number = window.innerWidth
  isSubscribed: boolean = false

  mobileShowSidebar: 'nav' | 'notify' | '' = ''

  modals = [];

  constructor(
    private store: Store<fromCoreStore.State>,
    private userActivityService: UserActivityService,
    private sidebarService: SidebarService,
    private _PushNotifications: PushNotifications,
    private cdr: ChangeDetectorRef
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth
    if(this.loggedIn && !location.href.includes('/checkout/')){
      this.showNavigation = true
      this.showSidebarRight = true
    }
    this.cdr.detectChanges()
    
  }

  onModalClose(type: string) {
    this.store.dispatch(new fromModalStore.CloseModal(type))
  }

  toggleMenu() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }


  toggleSidebar() {
    this.sidebarService.setIsOpenSidebar(this.activateMenu)
  }

  ngOnInit() {
    this.sidebarService.showNavigation$.subscribe((showNav) => {
      this.showNavigation = showNav;
      this.cdr.detectChanges()
    });


    this.store.select(fromModalStore.selectAllModals).subscribe(modals => {
      this.modals = modals;
      this.cdr.detectChanges()
    })
    

    this.sidebarService.showSidebar$.subscribe((showSidebar) => {
      this.showSidebarRight = showSidebar;
      this.cdr.detectChanges()
    });

    this.store.select(fromAuthStore.getLoggedIn).pipe(
      tap(logged => {
        this.loggedIn = logged
        if(logged && !location.href.includes('/checkout/')){
          this.showNavigation = true
          this.showSidebarRight = true
        }else{
          this.showNavigation = false
          this.showSidebarRight = false
        }
        // Bug with sidebars on mobile
        this.cdr.detectChanges()
      })
    ).subscribe()

    if (typeof Notification === 'undefined') {
      window.Notification = function() {} as any
    }

    if ( location.href.includes('/checkout/')) {
      this.showNavigation = false
      this.showSidebarRight = false
      this.showPreloader = false
    }
    Notification?.requestPermission?.()
    this.store.dispatch(new GetNewVersionNotification({version: this.appVersion}))
    console.log('App Version: ', this.appVersion);
    this.store.select(fromCoreStore.getRouterState).pipe(
      tap(v =>  {
        if(location.href.includes('/checkout/')){
          this.showNavigation = false
          this.showSidebarRight = false
          this.showPreloader = false
        }
        this.isPublic = v?.state?.data?.isPublic
      })
    ).subscribe();
    this.currentUser$ = this.store.select(fromAuthStore.getUser);
    this.userActivityService.networkingUserActivityObs$.subscribe()

    initFlowbite();

    window.addEventListener("storage", (event)=>{
      if(event?.key === 'jwtToken' && event?.newValue === null && !location.href.includes('/checkout/')){
        this.store.dispatch(new fromAuthStore.Logout())
      }else if(event?.key === 'jwtToken' && event?.newValue !== null && event?.oldValue === null && !location.href.includes('/checkout/')){
        this.store.dispatch(new fromAuthStore.LoginToken())
      }
    }, false);

    if(location.href.includes('/checkout/')){
      this.showNavigation = false
      this.showSidebarRight = false
      this.showPreloader = false
    }
  }

  ngAfterViewInit() {
    this._PushNotifications.init(async () => {
      const oneSignalState = await this._PushNotifications.getSubscriptionState()
      this.isSubscribed = oneSignalState ? oneSignalState.isPushEnabled : false
    })
    this.store.select(fromCoreStore.getRouterState)
    .pipe(
      tap(v => {
        if (this.mainContainer) {
          this.mainContainer.nativeElement.scrollTop = 0;
        }
      })
    ).subscribe();
  }
}
