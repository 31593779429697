import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/core/store';
import { Toast } from '@app/core/models/toast.model';
import { ToastHelper } from '@app/core/services/toast.service';

@Component({
  selector: 'app-toasts',
  template: `
  <div id="toasts">
    @for(toast of toasts$ | async; track $index){
      <app-toast-item [toast]="toast"(remove)="removeFromCollection($event)"></app-toast-item>
    }
    </div>
  `,
  styles: [
    `
      #toasts {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 300px;
        z-index: 9999;
      }
    `
  ]
})
export class ToastListComponent implements OnInit {
  toasts$: Observable<Toast[]>;

  constructor(
    private store: Store<fromStore.State>,
    private notify: ToastHelper
  ) {}

  ngOnInit() {
    this.toasts$ = this.store.select(fromStore.getAllToast);
  }

  removeFromCollection(toast: Toast) {
    this.notify.close(toast);
  }
}
