import { Injectable } from '@angular/core'

import { combineLatest, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import * as fromAuth from '@app/users/store'
import { Store } from '@ngrx/store'

import { AuthService } from '@app/users/services'

@Injectable()
export class AuthGuard  {
  constructor(
    private store: Store<fromAuth.State>,
    private _AuthService: AuthService
  ) { }

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.select(fromAuth.getTokenChecked),
      this.store.select(fromAuth.getLoggedIn),
    ]).pipe(
      map(([checked, isLoggedIn]) => {
        const token = this._AuthService.getToken()
        if (isLoggedIn || (!token && location.href.includes('/v/'))) {
          return true
        }
        if (!token) {
          this.store.dispatch(new fromAuth.LoginRedirect())
        }
        if (!checked && token) {
          this.store.dispatch(new fromAuth.LoginToken())
        }
      }),
      filter(i => i),
    )
  }
}
