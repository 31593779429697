export class CustomFormValidators {
  static phone(control) {
    if (!control.value) {
      return null;
    }
    if (control.value.match(/^\d{10}$/) || control.value === '') {
      return null;
    } else {
      return { phone: false };
    }
  }
}
