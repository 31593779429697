import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import * as fromCoreStore from '@app/core/store';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Thread } from '@app/shared';

@Component({
  selector: 'app-company-message-container',
  templateUrl: './company-message-container.component.html',
  styleUrls: ['./company-message-container.component.css']
})
export class CompanyMessageContainerComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();

  thread: Thread;

  constructor(
    private store: Store<fromCoreStore.State>,
  ) {}

  ngOnInit() {
    this.store.select(fromCoreStore.selectLastGlobalNotReadThread)
      .pipe( takeUntil(this.destroyed$) )
      .subscribe(thread => this.thread = thread);
  }

  readMessage = () =>
    this.store.dispatch(new fromCoreStore.MakeMessagesAsRead({ thread_id: this.thread._id, ids: [this.thread.messages.last._id] }))

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
