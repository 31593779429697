import { RootState } from "@app/shared/services/store-util.service"
import { MetaReducer } from "@ngrx/store"
import { RootStoreActionTypes } from "../actions/rootStore.actions"

export const logout:  MetaReducer<RootState>  = (reducer) => {
    return function (state, action) {
      if (action.type === RootStoreActionTypes.LOGOUT) {
        window.localStorage.clear()
      }
      return reducer(action.type ===  RootStoreActionTypes.LOGOUT ? undefined : state, action);
    }
}