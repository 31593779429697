<div class="content">
  <h3 *ngIf="author" class="messageAuthor">{{ author }}</h3>
  <h5 *ngIf="subject" class="messageSubject">{{ subject }}</h5>
  <div *ngIf="text" class="messageContent">
    {{ text }}
    <!-- <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis</span> -->
  </div>
</div>
<button class="btn" (click)="close.emit()">
  <i class="fas fa-times"></i>
</button>