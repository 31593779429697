import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromCoreStore from '@app/core/store'
import * as fromModalStoreActions from '@app/modals/store/actions'
import { Subject, takeUntil } from 'rxjs'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import moment from 'moment'
import { OrderService } from '@app/core/services'
import { popupService } from '@app/shared/services/popup.service'

@Component({
  selector: 'app-update-follow-up-modal',
  templateUrl: './update-follow-up-modal.component.html',
  styleUrls: ['./update-follow-up-modal.component.scss']
})
export class EditFollowUpModalComponent implements OnInit, OnDestroy {

  @Input()
  set props(val) {
    if (val) {
      // console.log(val)
      if (val?.billingContact) {
        this.contact = val?.billingContact
      }
      if (val?.orderId) {
        this.setCurrentOrder(val.orderId)
      }
      this.isTask = val?.isTask || false
      if (val?.followUp) {
        this.form.patchValue({
          types: val.followUp.types || [],
          sentAt: moment(val.followUp.sentAt) || '',
          description: val.followUp.description || '',
          outcome: val.followUp.outcome || '',
          direction: val.followUp.direction || '',
          orderId: val.followUp.orderId || '',
          subject: val.followUp.subject || '',
          templateId: val.followUp?.templateId || '',
          emailBody: val.followUp?.body || '',
          smsBody: val.followUp?.body || '',
          _id: val.followUp?._id
        })

        if (val.followUp.types[0] == 'Call') {
          this.form.patchValue({
            phones: val.followUp?.additionalData?.phones,
            creator: val.followUp.assignees[0]
          })
        }
        if (val.followUp.types[0] == 'Email') {
          this.form.patchValue({
            emails: val.followUp?.to || [],
            creator: val.followUp.creator
          })
        }
        if (val.followUp.types[0] == 'SMS') {
          this.form.patchValue({
            phones: val.followUp?.to || [],
            creator: val.followUp.creator
          })
        }
        this.cdr.detectChanges()
      }
    }
  }
  isTask = false
  contant = null

  type = 'phone'
  inputMasks = {
    phone: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }

  contact = null
  times = []
  timeTypes = [{ key: 'AM', name: 'AM' }, { key: 'PM', name: 'PM' }]
  touchpoints = [{ key: 'Call', name: 'Call' }, { key: 'SMS', name: 'SMS' }, { key: 'Email', name: 'Email' }]
  typesWarning = false
  dateWarning = false
  emailsPhonesWarning = false
  smsWarning = false
  emailWarning = false
  templates = []
  currentOrder = null

  destroyed$ = new Subject<boolean>()
  form: FormGroup

  constructor(
    private store: Store<fromCoreStore.State>,
    private cdr: ChangeDetectorRef,
    private _OrderService: OrderService,
    private fb: FormBuilder,
    private popupService: popupService
  ) {
    this.form = this.fb.group({
      types: [[], Validators.required],
      sentAt: ['', Validators.required],
      emails: [[], [Validators.required, Validators.email]],
      phones: [[], Validators.required],
      description: ['', Validators.required],
      creator: [{}, Validators.required],
      outcome: [''],
      direction: ['Outbound'],
      orderId: [''],
      subject: [''],
      emailBody: ['', Validators.required],
      smsBody: ['', Validators.required],
      templateId: [''],
      _id: [''],
    })
  }

  ngOnInit(): void {
    this.times = this.generateTimeIntervals()

    this.store.dispatch(new fromCoreStore.GetTemplates({ fields: ['name', 'order'], 'devices': 'web' }))

    this.store.select(fromCoreStore.selectCompiledTemplate)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(template => {
        if (template) {
          this.form.patchValue({
            subject: template.subject,
            smsBody: template.sms,
            emailBody: template.email,
            templateId: template.templateId
          })
        }
      })

    this.store.select(fromCoreStore.selectAllTemplates)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(templates => {
        if (templates) {
          this.templates = templates
          this.cdr.detectChanges()
        }
      })
  }

  close() {
    this.store.dispatch(new fromModalStoreActions.CloseModal())
  }

  changeTemplate(_id: string) {
    if (_id) {
      this.store.dispatch(new fromCoreStore.CompileTemplate({ _id, data: this.currentOrder }))
    } else {
      this.store.dispatch(new fromCoreStore.CompileTemplateSuccess({
        template: {
          _id: '',
          subject: '',
          sms: '',
          email: ''
        }
      }))
    }
  }

  changeDate(event: string) {
    const currentSentAt = this.form.get('sentAt')?.value
    const currentMoment = moment(currentSentAt).utc()
    const newDate = moment(event)

    currentMoment.set({
      year: newDate.year(),
      month: newDate.month(),
      date: newDate.date(),
    })

    this.form.get('sentAt')?.setValue(currentMoment.toISOString())
  }

  onSaveTime(event: string) {
    const currentDate = this.form.get('sentAt').value
    const momentDate = moment(currentDate).utc()

    const currentAmPm = momentDate.format('A')
    const [newHours, newMinutes] = event.split(':').map(Number)

    let adjustedHours = newHours

    if (currentAmPm === 'PM' && newHours < 12) {
      adjustedHours += 12
    } else if (currentAmPm === 'AM' && newHours === 12) {
      adjustedHours = 0
    }

    momentDate.set({
      hour: adjustedHours,
      minute: newMinutes,
      second: 0,
      millisecond: 0
    })

    this.form.get('sentAt')?.setValue(momentDate.toISOString())
  }
  async deleteFollowUp(item) {
    let type = ''
    if (item?.types[0] == "Call") {
      type = 'task'
    } else {
      type = 'schedule'
    }
    if ((await this.popupService.confirm('Are you sure you want to delete?'))) {
      this.store.dispatch(new fromCoreStore.DeleteFollowUp({ item, type }))
    }
    this.close()
  }

  onSaveTimeType(event: string) {
    const currentDate = this.form.get('sentAt').value;
    const momentDate = moment(currentDate)
  
    if (event === 'AM') {
      if (momentDate.hour() >= 12) {
        momentDate.subtract(12, 'hours');
      }
    } else if (event === 'PM') {
      if (momentDate.hour() < 12) {
        momentDate.add(12, 'hours');
      }
    }
  
    this.form.get('sentAt')?.setValue(momentDate.toISOString());
  }

  async setCurrentOrder(_id) {
    this.currentOrder = (await this._OrderService.get(_id, {
      populate: [
        '_local.carrier.contacts',
        '_local.billing.contacts',
        '_local.vehicles',
        '_local.origin',
        '_local.destination'
      ]
    }).toPromise())?.data

    this.currentOrder = {
      ...this.currentOrder,
      billing: {
        ...this.currentOrder.billing,
        accountContact: this.currentOrder._local?.billing?.contacts?.find(c => c._id === this.currentOrder.billing.accountId),
        contacts: this.currentOrder._local?.billing?.contacts || [],
      },
      carrier: {
        ...this.currentOrder.carrier,
        accountContact: this.currentOrder._local?.carrier?.contacts?.find(c => c._id === this.currentOrder.carrier.accountId),
        contacts: this.currentOrder._local?.billing?.contacts || [],
      },
      vehicles: this.currentOrder._local?.vehicles || [],
      origin: this.currentOrder._local?.origin,
      destination: this.currentOrder._local?.destination,
    }
  }

  send() {

    this.typesWarning = this.form.get('types')?.invalid
    this.dateWarning = this.form.get('sentAt')?.invalid
    this.smsWarning = this.form.get('smsBody')?.invalid
    this.emailWarning = this.form.get('emailBody')?.invalid

    if (this.form.get('types')?.invalid || this.form.get('sentAt')?.invalid || (this.form.get('types')?.value?.includes('SMS') && this.form.get('smsBody')?.invalid) || (this.form.get('types')?.value?.includes('Email') && this.form.get('emailBody')?.invalid) || (this.form.get('emails')?.invalid && this.form.get('phones')?.invalid)) {
      return
    }

    this.store.dispatch(new fromCoreStore.UpdateFollowUp({ item: this.form.value, type: !this.isTask ? 'schedule' : 'task' }))
    this.store.dispatch(new fromCoreStore.CleanTemplateStore())
    this.close()
  }

  generateTimeIntervals(): { key: string, name: string }[] {
    const intervals: { key: string, name: string }[] = []
    let hour = 0
    let minute = 0

    while (hour < 12) {
      const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
      intervals.push({ key: formattedTime, name: formattedTime })

      minute += 5
      if (minute === 60) {
        minute = 0
        hour += 1
      }
    }

    return intervals
  }

  truncateString(str) {
    if (str?.length > 30) {
      return str.slice(0, 30) + '...'
    }
    return str
  }

  onPhoneCheckboxChange(event: any, phone: string) {
    const phonesArray = this.form.get('phones')?.value || []

    if (event.target.checked) {
      phonesArray.push(phone)
    } else {
      const index = phonesArray.indexOf(phone)
      if (index > -1) {
        phonesArray.splice(index, 1)
      }
    }

    this.form.get('phones')?.setValue(phonesArray)
  }

  onEmailCheckboxChange(event: any, email: string) {
    const emailsArray = this.form.get('emails')?.value || []

    if (event.target.checked) {
      emailsArray.push(email)
    } else {
      const index = emailsArray.indexOf(email)
      if (index > -1) {
        emailsArray.splice(index, 1)
      }
    }

    this.form.get('emails')?.setValue(emailsArray)
  }

  applyMask(input, mask) {
    const inputChars = input.split('')
    let result = ''
    let inputIndex = 0

    for (let i = 0; i < mask.length; i++) {
      if (inputIndex < inputChars.length) {
        if (mask[i] instanceof RegExp) {
          if (mask[i].test(inputChars[inputIndex])) {
            result += inputChars[inputIndex]
            inputIndex++
          }
        } else {
          result += mask[i]
        }
      }
    }

    return result
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }


}
