import { Injectable, ErrorHandler } from '@angular/core'

import * as Sentry from "@sentry/angular"

import { environment } from '../../../environments/environment'
declare var require: any
const appVersion = require('../../../../package.json').version


@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  constructor() {
    Sentry.init({
      release: appVersion,
      dsn: 'https://7d89057c5f084527b9648ef84940c958@sentry.io/797116',
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      beforeSend(event, hint) {
        const isNonErrorException =
          event?.exception?.values[0]?.value?.startsWith('Non-Error exception captured') ||
          hint?.originalException['message']?.startsWith('Non-Error exception captured')
        if (isNonErrorException) {
          // We want to ignore those kind of errors
          return null
        }
        return event
      },
      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        })
      ]
    })
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error.message || error)
  }
}

export function getErrorHandler(): ErrorHandler {
  if (environment.production) {
    return new SentryErrorHandler()
  }
  return new ErrorHandler()
}
