@if(currentUser$ | async; as currentUser){
  <div style="overflow: hidden;">
  @if(!userIsCustomer()){
    <div
    class="sidebar__menu-wrapper"
    [ngClass]="options.isActiveSidebar || options.mobile ? 'sidebar-left__active' : 'sidebar__inactive'">
    <div *ngVar="canDisplayExpirationList$ | async as canDisplayExpirationList" [ngClass]="options.isActiveSidebar || options.mobile ? 'sidebar__menu-open' : 'sidebar__menu'">
      <div [ngClass]="options.isActiveSidebar || options.mobile ? 'bar-icons-wrapper-open' : 'bar-left__wrapper'">
        <div  [ngClass]="options.isActiveSidebar || options.mobile ? 'bar-menu-icons-open' : 'bar__menu-left'">
          <div  [ngClass]="options.isActiveSidebar || options.mobile ? 'bar-menu-icons-nav-open' : 'bar__menu-nav'">
            <ul>
              <li
                class="alarm__container"
                [ngClass]="activeMenuTab() == 'Notifications' && 'active'"
                (click)="updateMenuActive('Notifications')"
                title="Notifications"
              >
                <i class="fa-solid fa-bell text-gray-500"></i>
                @if(notificationsUnReadCount$ | async; as notificationsUnReadCount){
                    <span class="count right-0" >{{ notificationsUnReadCount }}</span >
                  }
              </li>

              <li
                class="expirations__container"
                [ngClass]="activeMenuTab() == 'My Tasks' && 'active'"
                (click)="updateMenuActive('My Tasks')"
                title="My Tasks"
              >
                <i class="fa-solid fa-list-check text-gray-500"></i>
                @if(tasksUnReadCount$ | async; as tasksUnReadCount){
                  <span class="count">{{ tasksUnReadCount }}</span>
                }
              </li>

              <li
                [ngClass]="activeMenuTab() == 'Messages' && 'active'"
                class="expirations__container"
                (click)="updateMenuActive('Messages')"
              >
                <i class="fa-solid fa-comment text-gray-500"></i>
                @if(unreadThreadsCount$ | async; as unreadThreadsCount){
                  <span class="count">{{ unreadThreadsCount }}</span>
                }
              </li>
              <li
                [ngClass]="activeMenuTab() == 'Users' && 'active'"
                class="users__container"
                (click)="updateMenuActive('Users')"
                title="Users"
              >
                <i class="fa-solid fa-users text-gray-500"></i>
              </li>
              @if(canDisplayExpirationList){
                <li
                  [ngClass]="activeMenuTab() == 'Expirations' && 'active'"
                  class="expirations__container"
                  (click)="updateMenuActive('Expirations')"
                  title="Expirations"
                >
                  <i class="fa-solid fa-file-lines text-gray-500"></i>
                  @if(expiredDatesCount$ | async; as expiredDatesCount){
                    <span
                    class="count"
                    >{{ expiredDatesCount }}</span>
                  }
                </li>
              }
              @if(options.isActiveSidebar || options.mobile){
                  <li (click)="options.mobile ? onNavMobileClose.emit() : onActiveMenu.emit(false); activeMenuTab.set('')">
                    <i class="fas fa-times"></i>
                  </li>
              }
            </ul>
          </div>
        </div>
      </div>
      @if(options.isActiveSidebar || options.mobile){
        <div class="bar__menu-right">
          <!-- <div class="bar-content__title-wrapper">
            <div class="bar-content__title ">
              <p>{{ activeMenuTab() }}</p>
            </div>
          </div> -->

          <div class="side-bar-content-container">
                @if(activeMenuTab() == 'Notifications' || options.mobile && activeMenuTab() === ''){
                  <app-header-events [users]="users$ | async" [currentUser]="currentUser" type="notes" (onMobileClick)="onNavMobileClose.emit()" [options]="{
                    mobile: options?.mobile
                  }"></app-header-events>
                } @else if(activeMenuTab() == 'My Tasks'){
                    @defer (on viewport) {
                      <app-tasks-container
                        [fromStore]="fromSidebarStore"
                        [currentUser]="currentUser"
                        [resource]="{
                          resource: FOLLOW_UP_RESOURCE.COMPANY,
                          resourceId: currentUser?.company?._id,
                          resourceName: currentUser?.company?.name
                        }"
                      ></app-tasks-container>
                    } @placeholder {
                      <span>Loading Tasks...</span>
                    }
                } @else if(activeMenuTab() == 'Messages'){
                <app-diesel-chat
                  [fromStore]="fromSidebarStore"
                  [currentUser]="currentUser"
                  [options]="{redirect: true, edit: true,  delete: true, archive: true}"
                ></app-diesel-chat>
              } @else if (activeMenuTab() == 'Users'){
                <app-sidebar-users></app-sidebar-users>
              } @else if(activeMenuTab() == 'Expirations' && canDisplayExpirationList){
                <app-expiration-list [currentUser$]="currentUser$"></app-expiration-list>
              }
          </div>
        </div>
    }
  </div>

  </div>
}
</div>
}
