import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';

import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators
} from '@angular/forms';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subject, of } from 'rxjs';
import { tap, takeUntil, finalize, switchMap } from 'rxjs/operators';

import { GeocodeService } from '@app/shared/services/geocode.service'

import * as fromAccountStore from '@app/crm/store';
import * as fromCoreStore from '@app/core/store';
import * as fromUserStore from '@app/users/store';
import { selectAllUsers } from '@app/sidebar/store/selectors/user.selectors'

import { ToastHelper } from '@app/core/services/toast.service';

import { AccountDataService } from '@app/shared/services/account-data.service';

import { Contact, User } from '@app/shared/models';

import { CustomFormValidators } from '@app/shared/validators/custom-form.validators';
import { FOLLOW_UP_RESOURCE } from '@app/shared/models/followUpTypes.modal'
import { environment } from 'environments/environment';
import * as fromCoreStores from '@app/core/store'
import * as fromModalStore from '@app/modals/store'
import * as fromStore from '@app/core/store';
import * as fromModalStoreActions from '@app/modals/store/actions'
import { popupService } from '@app/shared/services/popup.service'
import { CloseModal } from '@app/modals/store/actions'

@Component({
  selector: 'app-account-form-modal',
  templateUrl: './account-form-modal-component.html',
  styleUrls: ['./account-form-modal-component.css'],
})
export class AccountFormModalComponent implements OnInit, OnDestroy {
  @Output() closeEvent = new EventEmitter();

  @Input() props

  users$: Observable<User[]>;  
  destroyed$ = new Subject<boolean>();

  types: Object[];

  currentUser: User;
  states;
  users;

  FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE

  apiUrl = environment.apiUrl;

  formAccount;
  formMain;
  formPrimary;

  assignee: {
    _id: string;
    name: string;
  };

  selectedTypeId: string;

  closeResult: string;
  accountId: string;
  account = {
    main: {
      name: '',
      title: '',
      isAccount: true,
      location: {},
      address: {
        addressLine1: '',
        city: '',
        state: '',
        zip: ''
      },
      phones: [
        {
          type: 'Account',
          value: ''
        }
      ],
      emails: [],
      type: '',
      status: '',
      creator: {
        _id: '',
        name: ''
      }
    },
    primary: {
      name: '',
      title: '',
      isAccount: false,
      phones: [
        {
          type: 'Direct',
          value: ''
        }
      ],
      emails: [
        {
          type: 'Work',
          value: ''
        }
      ],
      type: '',
    },
    sharedMeta: {
      account: {
        logo: {
          fileId: '',
          uploadId: ''
        }
      }
    },
    meta: {
      stage: 'New',
      cars_per_year: 0,
      assignee: {
        _id: '',
        name: ''
      }
    }
  };

  selectedStatusKey = 'new-account';
  assigneeId;
  status;
  imageURL: string = '';
  uploadForm: FormGroup;
  contact

  fromStore: typeof fromCoreStore | typeof fromModalStore = fromCoreStore
  
  constructor(
    private router: Router,
    public notify: ToastHelper,
    private store: Store<fromAccountStore.State>,
    private coreStore: Store<fromCoreStore.State>,
    private actions$: Actions,
    private _accountDataService: AccountDataService,
    private fb: UntypedFormBuilder,
    private stores: Store<fromStore.State>,
    public geocodeService: GeocodeService,
    private popupService: popupService,
  ) {
    // this.userService.currentUser?.subscribe(userData => {
    //   this.currentUser = userData;
    //   this.account.account.creator = {
    //     _id: this.currentUser?._id,
    //     name: this.currentUser?.name
    //   };
    // });
    this.uploadForm = this.fb.group({
      avatar: [null]
    })
  }

  onSaveFullAddress(event) {
    let fullAddress = ''
    this.formMain.get('addressLine1').setValue(event)
    for (const [key, value] of Object.entries(this.formMain.value)) {
      if (key != '_id' && key != 'addressLine2' && value != '' && value != null) {
        fullAddress += `${value} `
      }
    }
    this.geocodeService.getCoordinatesByAddress(fullAddress).pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      const location = { 
        lat: res.data.lat, 
        lng: res.data.lng 
      }
      this.account.main.location = location
      }, async (err)=>{await this.popupService.alert(err)})
  }

  onSaveSityAndStateAddress (event) {
    this.formMain.get('state').setValue(event)
    let fullAddress = ''
    for (const [key, value] of Object.entries(this.formMain.value)) {
      if (key != '_id' && key != 'addressLine2' && value != '' && value != null) {
        fullAddress += `${value} `
      }
    }
    this.geocodeService.getCoordinatesByAddress(fullAddress).pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      const location = { 
        lat: res.data.lat, 
        lng: res.data.lng 
      }
      this.account.main.location = location
      }, async (err)=>{await this.popupService.alert(err)})
  }


  ngOnInit() {
    this.actions$
      .pipe(
        ofType<typeof fromCoreStore.CreateAccountSuccess.prototype>(
          fromCoreStore.AccountActionTypes.ADD_ONE_SUCCESS
        ),
        takeUntil(this.destroyed$),
        tap(action => {
          this.contact = action.payload.account
        })
      )
      .subscribe();

    this.store
      .select(fromUserStore.getUser)
      .pipe(
        takeUntil(this.destroyed$),
        tap(user => {
          this.currentUser = user;
          this.account.main.creator = {
            _id: this.currentUser?._id,
            name: this.currentUser?.name
          };
        })
      )
      .subscribe();

    this.users$ = this.coreStore.select(selectAllUsers).pipe(
      tap(users => {
        this.users = users;
      })
    );

    this.store
      .select(fromCoreStore.selectCoreValContacts)
      .pipe(
        takeUntil(this.destroyed$),
        tap(val => {
          if (val.statuses && val.statuses.length > 0) {
            this.account.main.status = val.statuses[0].key ? val.statuses[0].key : val.statuses[1].key;
          }
          if (val.types) {
            this.types = val.types.filter(t => t.isCompany)
          }
        })
      )
      .subscribe();

    this.states = this._accountDataService.states.map(s=>{
      return {key:s, name:s}
    })

    // this.currentUser?._id
    this.formAccount = this.fb.group({
      assignee: new UntypedFormControl(this.currentUser?._id, {
        validators: [Validators.required],
        updateOn: 'blur'
      }),
      type: new UntypedFormControl('', {
        validators: [Validators.required],
        updateOn: 'blur'
      })
    });

    this.formMain = this.fb.group({
      name: new UntypedFormControl('', {
        validators: [Validators.required],
        updateOn: 'blur'
      }),
      addressLine1: new UntypedFormControl(''),
      city: new UntypedFormControl(''),
      state: new UntypedFormControl(''),
      phone: new UntypedFormControl('', {
        validators: [CustomFormValidators.phone]
      }),
    });

    this.formPrimary = this.fb.group({
      name: new UntypedFormControl('', {
        validators: [Validators.required],
        updateOn: 'blur'
      }),
      avatar: [null],
      title: new UntypedFormControl(''),
      phone: new UntypedFormControl('', {
        validators: [CustomFormValidators.phone]
      }),
      email: new UntypedFormControl('')
    });
  }

  showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadForm.patchValue({
      avatar: file
    });
    this.uploadForm.get('avatar').updateValueAndValidity()
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  uploadSuccess(file) {
    if (file) {
        this.contact.sharedMeta.account.logo = {
          fileId: file?._id,
          uploadId: file?.uploadId,
        }
    }
  }

  uploadError(error) {
    this.notify.error('Something went wrong with the photo upload!');
  }

  get typeAccount() {
    return this.formAccount.get('type') as UntypedFormControl;
  }

  get assigneeAccount() {
    return this.formAccount.get('assignee') as UntypedFormControl;
  }

  get phoneAccount() {
    return this.formMain.get('phone') as UntypedFormControl;
  }

  get nameMain() {
    return this.formMain.get('name') as UntypedFormControl;
  }

  get namePrimary() {
    return this.formPrimary.get('name') as UntypedFormControl;
  }

  get phonePrimary() {
    return this.formPrimary.get('phone') as UntypedFormControl;
  }

  get emailPrimary() {
    return this.formPrimary.get('email') as UntypedFormControl;
  }

  async onSubmit()  {
    try {
      // console.log(this.formMain.value)
      if (
        this.formMain.invalid ||
        this.formAccount.invalid ||
        this.formPrimary.invalid
      ) {
        this.notify.error('Please enter valid form inputs!');
        Object.keys(this.formMain.controls).forEach(field => {
          const control = this.formMain.get(field);
          control.markAsTouched({ onlySelf: true });
        });
        Object.keys(this.formAccount.controls).forEach(field => {
          const control = this.formAccount.get(field);
          control.markAsTouched({ onlySelf: true });
        });
        Object.keys(this.formPrimary.controls).forEach(field => {
          const control = this.formPrimary.get(field);
          control.markAsTouched({ onlySelf: true });
        });
        return;
      }
      // Getting Assignee
      const assignee = this.users.filter(
        user => user._id === this.formAccount.get('assignee').value
      );
  
      // Setting Account
      this.account.meta.assignee = {
        _id: assignee[0]._id,
        name: assignee[0].name
      };
      this.account.main.type = this.formAccount.get('type').value;
  
      // Setting Main
      const address = this.formMain.value;
      delete address.name;
      delete address.phone;
      this.account.main.name = this.formMain.get('name').value;
      this.account.main.phones[0].value = this.formMain.get('phone').value;
      this.account.main.address = address;
      this.account.main.title = this.formPrimary.get('title').value;
  
      // Setting Primary
      this.account.primary.title = this.formPrimary.get('title').value;
      this.account.primary.name = this.formPrimary.get('name').value;
      this.account.primary.emails[0].value = this.formPrimary.get('email').value;
      this.account.primary.type = this.formAccount.get('type').value;
      if (!this.formPrimary.get('phone').value) {
        delete this.account.primary.phones;
      } else {
        this.account.primary.phones[0].value = this.formPrimary.get(
          'phone'
        ).value;
      }
  
      // Contact
      this.contact.meta.account.assignee = this.account.meta.assignee
      this.contact.type = this.account.main.type
      this.contact.name = this.account.main.name
      this.contact.phones = this.account.main.phones
      this.contact.address = this.account.main.address;
      this.contact.title = this.account.main.title;
  
      const id = this.contact._id

      this.store.dispatch( 
        new fromCoreStore.PatchContact({
          contact: {
            id: this.contact._id,
            changes: this.contact
            },
          createMessage: 'create'
        })
      );


      this.store.dispatch(
        new fromCoreStore.UpdateContact({ 
          contact: { 
            id: this.contact.primaryContactId, 
            changes: this.account.primary 
          } 
        })
      )
      
      this.router.navigate(['/crm/accounts/' + id]);
      this.store.dispatch(new CloseModal());
    } catch (error) {
      console.error(error);
    }
  }

  removeLogo() {
    if (this.contact.sharedMeta.account.logo.fileId.length) {
      this.stores.dispatch(new fromStore.DeleteFile({_id: this.contact.sharedMeta.account.logo.fileId }));
      this.contact.sharedMeta.account.logo = { fileId: '', uploadId: '' }
    }
  }

  close() {
    // this.contact.not_notify = true
    this.store.dispatch(
      new this.fromStore.DeleteContact({
        accountId: this.contact._id,
        contact: this.contact, 
        createMessage: false
      })
    )

    if (this.contact.sharedMeta.account.logo.fileId.length) {
      this.stores.dispatch(new fromStore.DeleteFile({_id: this.contact.sharedMeta.account.logo.fileId }));
      // this.contact.sharedMeta.account.logo = { fileId: '', uploadId: '' }
    }

    this.store.dispatch(
      new this.fromStore.DeleteContact({
        accountId: this.contact.contacts[0],
        contact: {
          _id: this.contact.contacts[0]
        },
        createMessage: false
      })
    )

    this.store.dispatch(new CloseModal())
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
