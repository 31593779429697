<div class="menu-box">
  <div class="header">
    <div class="row" style=" padding:1rem;">
      <div class="col-md-2">

      </div>
      <div class="col-md-10" style="margin-top: -0.3rem;">
        <div class="logo">
          <img src="assets/favicon.png" style="height: 26px;margin-right: 5px;margin-top: -3px; " />
          <span style="font-family: 'Titillium Web', sans-serif;font-size: 1.3rem; color:white;
                color: white;">Diesel Dispatch</span>
        </div>
      </div>
      <!-- <div class="col-md-2" style="margin-top:-0.5rem;">
            <a (click)="toggleMenu()"><i class="fas fa-ellipsis-v" style="font-size:2rem; color:white"></i></a>
        </div> -->
    </div>
  </div>
  <div style="padding:1rem;">
    <ul class="m-nav " style="padding:0rem;     line-height: 2.4;">
      <li class="m-nav__item">
        <a class="m-nav__link" style="padding:0rem" [routerLink]="['/crm']">
          <i class="m-nav__link-icon fas fa-user-tie"></i>
          <span class="m-nav__link-text ">
            CRM
          </span>
        </a>
      </li>
      <li class="m-nav__item">
        <a class="m-nav__link" style="padding:0rem" [routerLink]="['/orders']">
          <i class="m-nav__link-icon far fa-map"></i>
          <span class="m-nav__link-text">
            LOADBOARD
          </span>
        </a>
      </li>
      <li class="m-nav__item">
        <a class="m-nav__link" style="padding:0rem" [routerLink]="['/driver']">
          <i class="m-nav__link-icon far fa-user"></i>
          <span class="m-nav__link-text">
            DRIVER
          </span>
        </a>
      </li>
      <li class="m-nav__item">
        <a class="m-nav__link" style="padding:0rem" [routerLink]="['/equipment']">
          <i class="m-nav__link-icon fas fa-truck-moving"></i>
          <span class="m-nav__link-text">
            EQUIPMENT
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>
