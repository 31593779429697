<div class="flex filter-wrapper mb-1">
  <div class="filter-container">
    <app-select
      [list]="filterTypes"
      (onSave)="filterByResource(ExpirationResourceEnum[$event])"
    ></app-select>
  </div>
  <div class="filter-container">
    <app-select
      [list]="daysList"
      [placeholder]="'Days'"
      (onSave)="filterByDays($event)"
    ></app-select>
  </div>
</div>



<div class="relative overflow-x-auto">
  <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead style="font-size: 11px;" class=" text-gray-700 uppercase dark:text-gray-400 border-b">
          <tr>
              <th scope="col" style="width:108px" class="px-3 col-heading py-3 bg-gray-50 dark:bg-gray-800">
                NAME/UNIT
              </th>
              <th scope="col" class="px-3 col-heading py-3">
                TYPE
              </th>
              <th scope="col" class="px-3 col-heading py-3 bg-gray-50 dark:bg-gray-800">
                EXPIRED
              </th>
          </tr>
      </thead>
      <tbody style="font-size: 11px;">
        @for(expiration of _expirations | sort: 'expiredDate'; track expiration._id){
          <tr class="border-b border-gray-200 dark:border-gray-700">
            <th class="th-block px-3 py-1 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
              <a class="block text-gray-900 th-block__text" style="max-width:90px;white-space: initial;" href="javascript:void(0)" (click)="openResourceModal(expiration.resource, expiration.resourceId, expiration.companyId)">{{ expiration._resourceName || 'New Expiration' }}</a>
              <div *ngIf="expiration._resourceType" class="mt-1 th-block__badge">
                <span  class="text-green-900 bg-green-100 px-2 py-0.5 rounded-lg mt-1">{{ ExpirationResourceTextEnum[expiration._resourceType] }}</span>
              </div>
            </th>
            <th  class="px-2 py-1">
              @for(exp of expiration._typeUnion;let first = $first; track $index){
                <div class="experation-type-item">
                  <p [ngClass]="[!first ? 'border-t' : '']" class="text-gray-500 font-normal type-text">{{ ExpirationTypeTextEnum[exp] }}</p>
                </div>
              }
            </th>
            <th class="pl-3 pr-2 py-1 bg-gray-50 dark:bg-gray-800 text-center">
              @for( date of expiration._dateUnion;let first = $first; track $index){
                <div [ngClass]="[!first ? 'border-t' : '']">
                  <p
                    class="font-normal date-text"
                    [ngClass]="expiration._isExpired
                    && checkExpire(date)
                    && 'text-red-500'"
                    >{{ date | date: 'MM/dd/YY':'UTC' }}</p>
                </div>
              }
            </th>
          </tr>
        }
      </tbody>
  </table>
</div>
