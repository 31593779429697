import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import * as fromSidebarStore from '@app/sidebar/store'
import { Store } from '@ngrx/store'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-sidebar-users',
  templateUrl: './sidebar-users.component.html',
  styleUrls: ['./sidebar-users.component.scss']
})
export class SidebarUsersComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>()

  onlineUsers = []
  afkUsers = []
  offlineUsers = []

  constructor(private store: Store<fromSidebarStore.State>) {}

  ngOnInit(): void {
    this.store
      .select(fromSidebarStore.selectOnlineUsers)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(onlineUsers => (this.onlineUsers = onlineUsers))
    this.store
      .select(fromSidebarStore.selectAfkUsers)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(afkUsers => (this.afkUsers = afkUsers))
    this.store
      .select(fromSidebarStore.selectOfflineUsers)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(offlineUsers => (this.offlineUsers = offlineUsers))
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
