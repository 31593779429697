import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'

import { SharedModule, CoreComponentsModule } from '@app/shared'

import { HeaderEventsComponent } from '@app/sidebar/components/events/events.component'
import { SidebarUsersComponent } from '@app/sidebar/components/sidebar-users/sidebar-users.component'
import { ExpirationListComponent } from '@app/sidebar/components/expiration-list/expiration-list.component'
import { PipesModule } from '@app/shared/pipes'

export const COMPONENTS = [HeaderEventsComponent, SidebarUsersComponent, ExpirationListComponent]

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, SharedModule, CoreComponentsModule, PipesModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ComponentsModule {}
