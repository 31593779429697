<div class="adjusters-container bg-white rounded-lg">
    <div class="flex justify-between items-center modal-header-adjusters">
        <p class="text-gray-900 font-poppins">Adjusters Notes</p>
        <button class="" type="button" (click)="close()">
          <span aria-hidden="true">
            <i class="fas fa-close text-gray-400 text-lg"></i>
          </span>
        </button>
    </div>
    <div class="modal-body-adjusters bg-white">
        <div class="adjusters-block">
            <div class="adjusters-title flex items-center">
                <p>Pickup</p>
                <span>03/33/22 12:20 PM</span>
            </div>
            <div class="adjusters-photos flex">
                @for(photo of pickupInspection; track $index){
                    <div class="adjusters-photo-item">
                        <app-photo-inspection
                        [photo]="photo">
                        </app-photo-inspection>
                    </div>
                }
            </div>
        </div>
        <div class="adjusters-block">
            <div class="adjusters-title flex items-center">
                <p>Delivery</p>
                <span>03/33/22 12:20 PM</span>
            </div>
            <div class="adjusters-photos flex">
                @for(photo of deliveryInspection; track $index){
                    <div class="adjusters-photo-item">
                        <app-photo-inspection
                        [photo]="photo">
                        </app-photo-inspection>
                    </div>
                }
            </div>
        </div>
        <div class="adjusters-notes">
            <!-- <div class="adjusters-title">
                <p>Adjusters Notes</p>
            </div> -->
            <textarea [value]="adjustersNotes[0]?.content" (change)="changeNotes($any($event.target).value)" id="adjusters-notes" rows="4" class="block p-2.5 w-full adjusters-textarea text-sm text-gray-900 bg-gray-50 border-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
            placeholder="Adjuster Notes here..."
            ></textarea>
        </div>
    </div>
    <div class="adjusters-modal-footer bg-white rounded-b-lg text-right">
      <button class="close_btn" type="button" (click)="close()">
        Close
      </button>
    </div>
</div>








