import { Component, ChangeDetectionStrategy } from '@angular/core'

@Component({
  selector: 'app-release-notes-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: `./release-notes.html`,
  styles: [
    `
      :host {
        text-align: center;
      }
    `
  ]
})
export class ReleaseNotesPageComponent {
}
