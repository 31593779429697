import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromCoreStore from '@app/core/store'
import * as fromModalStoreActions from '@app/modals/store/actions'
import { Subject } from 'rxjs'
import { NgxPopperjsTriggers, NgxPopperjsPlacements } from 'ngx-popperjs'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import moment from 'moment'

@Component({
  selector: 'app-log-activity-modal',
  templateUrl: './log-activity-modal.component.html',
  styleUrls: ['./log-activity-modal.component.scss']
})
export class LogActivityModalComponent implements OnInit, OnDestroy {

  NgxPopperjsTriggers = NgxPopperjsTriggers
  NgxPopperjsPlacements = NgxPopperjsPlacements
  typesWarning = false
  dateWarning = false
  emailsPhonesWarning = false
  type = 'phone'
  inputMasks = {
    phone: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }

  @Input()
  set props(val) {
    if (val) {

      if (val?.orderId) {
        this.form.patchValue({
          orderId: val.orderId
        })
      }

      if (val?.billingContact) {
        this.contant = val?.billingContact
        this.form.patchValue({
          creator: {
            _id: val?.billingContact?._id,
            name: val?.billingContact?.name,
            companyId: val?.billingContact?.creator?.companyId
          }
        })
      }
    }
  }

  times = []
  timeTypes = [{ key: 'AM', name: 'AM' }, { key: 'PM', name: 'PM' }]
  touchpoints = [{ key: 'Call', name: 'Call' }, { key: 'SMS', name: 'SMS' }, { key: 'Email', name: 'Email' }]
  direction = [{ key: 'Inbound', name: 'Inbound' }, { key: 'Outbound', name: 'Outbound' }]
  outcomeGreen = [
    { key: 'Quote', name: 'Quote', color: '#046C4E', bg: '#DEF7EC' },
    { key: 'Future Shipment', name: 'Future Shipment', color: '#046C4E', bg: '#DEF7EC' },
    { key: 'Will Call Back', name: 'Will Call Back', color: '#046C4E', bg: '#DEF7EC' }
  ]
  outcomeYellow = [
    { key: 'Left Message', name: 'Left Message', color: '#8E4B10', bg: '#FEECDC' },
    { key: 'Could Not Reach', name: 'Could Not Reach', color: '#8E4B10', bg: '#FEECDC' },
    { key: 'Shopping Around', name: 'Shopping Around', color: '#8E4B10', bg: '#FEECDC' }
  ]
  outcomeRed = [
    { key: 'Hired Other Company', name: 'Hired Other Company', color: '#C81E1E', bg: '#FDE8E8' },
    { key: 'No Longer Shipping', name: 'No Longer Shipping', color: '#C81E1E', bg: '#FDE8E8' },
    { key: 'Bad Phone', name: 'Bad Phone', color: '#C81E1E', bg: '#FDE8E8' },
  ]
  form: FormGroup

  contant = null
  destroyed$ = new Subject<boolean>()

  constructor(
    private store: Store<fromCoreStore.State>,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      types: [[], Validators.required],
      sentAt: ['', Validators.required],
      emails: [[], [Validators.required, Validators.email]],
      phones: [[], Validators.required],
      description: ['', Validators.required],
      creator: [{}, Validators.required],
      outcome: [''],
      direction: ['Outbound'],
      orderId: [''],
      subject: ['']
    })

  }


  ngOnInit(): void {
    // console.log(this.props)
    // this.store.dispatch(new fromCoreStore.GetFollowUps({}))


    // this.store.select(fromCoreStore.selectAllFollowUps).pipe(
    //   takeUntil(this.destroyed$),
    //   tap(items => {
    //     console.log(items)
    //   })
    // ).subscribe()

    const formattedDate = moment();
    const remainder = 5 - (formattedDate.minute() % 5);
    const roundedDate = formattedDate.add(remainder, 'minutes').startOf('minute');
    this.form.patchValue({
      sentAt: roundedDate,
    });
    this.cdr.detectChanges()

  }
  onSaveTime(event: string) {
    const currentDate = this.form.get('sentAt').value
    const momentDate = moment(currentDate).utc()

    const currentAmPm = momentDate.format('A')
    const [newHours, newMinutes] = event.split(':').map(Number)

    let adjustedHours = newHours

    if (currentAmPm === 'PM' && newHours < 12) {
      adjustedHours += 12
    } else if (currentAmPm === 'AM' && newHours === 12) {
      adjustedHours = 0
    }

    momentDate.set({
      hour: adjustedHours,
      minute: newMinutes,
      second: 0,
      millisecond: 0
    })

    this.form.get('sentAt')?.setValue(momentDate.toISOString())
  }

  onSaveTimeType(event: string) {
    const currentDate = this.form.get('sentAt').value;
    const momentDate = moment(currentDate)
  
    if (event === 'AM') {
      if (momentDate.hour() >= 12) {
        momentDate.subtract(12, 'hours');
      }
    } else if (event === 'PM') {
      if (momentDate.hour() < 12) {
        momentDate.add(12, 'hours');
      }
    }
  
    this.form.get('sentAt')?.setValue(momentDate.toISOString());
  }

  generateTimeIntervals(): { key: string, name: string }[] {
    const intervals: { key: string, name: string }[] = []
    let hour = 0
    let minute = 0

    while (hour < 12) {
      const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
      intervals.push({ key: formattedTime, name: formattedTime })

      minute += 5
      if (minute === 60) {
        minute = 0
        hour += 1
      }
    }

    return intervals
  }

  close() {
    this.store.dispatch(new fromModalStoreActions.CloseModal())
  }

  truncateString(str) {
    if (str?.length > 30) {
      return str.slice(0, 30) + '...'
    }
    return str
  }

  onChangeOutcome(event) {
    const outcomeControl = this.form.get('subject');
  
    if (outcomeControl?.value === event) {
      outcomeControl.setValue('')
    } else {
      outcomeControl.setValue(event)
    }
  }

  onPhoneCheckboxChange(event: any, phone: string) {
    const phonesArray = this.form.get('phones')?.value || []

    if (event.target.checked) {
      phonesArray.push(phone)
    } else {
      const index = phonesArray.indexOf(phone)
      if (index > -1) {
        phonesArray.splice(index, 1)
      }
    }

    this.form.get('phones')?.setValue(phonesArray)
  }

  onEmailCheckboxChange(event: any, email: string) {
    const emailsArray = this.form.get('emails')?.value || []

    if (event.target.checked) {
      emailsArray.push(email)
    } else {
      const index = emailsArray.indexOf(email)
      if (index > -1) {
        emailsArray.splice(index, 1)
      }
    }

    this.form.get('emails')?.setValue(emailsArray)
  }

  onChangeTypes(type) {
    const typesArray = this.form.get('types')?.value || [];

    if (typesArray.includes(type)) {
      const updatedArray = typesArray.filter(item => item !== type);
      this.form.get('types')?.setValue(updatedArray);
    } else {
      this.form.get('types')?.setValue([...typesArray, type]);
    }
  }

  applyMask(input, mask) {
    const inputChars = input.split('')
    let result = ''
    let inputIndex = 0

    for (let i = 0; i < mask.length; i++) {
      if (inputIndex < inputChars.length) {
        if (mask[i] instanceof RegExp) {
          if (mask[i].test(inputChars[inputIndex])) {
            result += inputChars[inputIndex]
            inputIndex++
          }
        } else {
          result += mask[i]
        }
      }
    }

    return result
  }

  send() {
    // console.log(this.form.value)
    this.typesWarning = this.form.get('types')?.invalid
    this.dateWarning = this.form.get('sentAt')?.invalid
    this.emailsPhonesWarning = this.form.get('emails')?.invalid && this.form.get('phones')?.invalid

    if (this.form.get('types')?.invalid || this.form.get('sentAt')?.invalid || (this.form.get('emails')?.invalid && this.form.get('phones')?.invalid)) {
      return
    }

    this.store.dispatch(new fromCoreStore.CreateFollowUp({ item: this.form.value, type: 'log' }))
    this.store.dispatch(new fromCoreStore.CleanTemplateStore())
    this.close()
  }


  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }


}
