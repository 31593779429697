import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    ChangeDetectorRef,
    Inject,
    ViewChild
  } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subject, distinctUntilChanged, takeUntil, tap } from 'rxjs';
import { MapInfoWindow } from "@angular/google-maps";
import * as fromStore from '@app/claims/store';
import * as fromCoreStore from '@app/core/store';
import { CloseModal } from '@app/modals/store/actions/layout.actions';
import { GetCoordinatesService } from '@app/tracking/container/tracking/GetCoordinatesService.service'
import * as fromClaimStore from '@app/claims/store'

@Component({
    selector: 'app-claim-shops-nearby',
    templateUrl: './claim-shops-nearby.component.html',
    styleUrls: ['claim-shops-nearby.component.scss']
})

export class ClaimShopsNearby implements OnInit, OnDestroy {
    @ViewChild('map') mapElement
    @ViewChild(MapInfoWindow, { static: false }) info;

    destroyed$ = new Subject<boolean>();
    map;
    @Input()
    set props (v) {
      if (v) {
        this._props = v
        // this.shop = v?.props?.shop
        this.location = v?.location
        this.vehicleLocation = v?.location
      }
    }

    _props
    filters
    shop
    currentAccount
    selectedCompany
    accounts
    type
    geocoder
    coordinates
    location
    vehicleLocation

    infoWindowOptions: google.maps.InfoWindowOptions = {
      pixelOffset: new google.maps.Size(0, -30)
    };

    constructor(
      private store: Store<fromStore.State>,
      private coreStore: Store<fromCoreStore.State>,
      private cdr: ChangeDetectorRef,
      @Inject(GetCoordinatesService) private getCoordinatesService: GetCoordinatesService
    ) {}

    ngOnInit() {
      this.getCoordinatesService.getLatLng$.subscribe(() => this.eventMapListener());
      this.geocoder = new google.maps.Geocoder();
      this.store.select(fromClaimStore.selectClaimShops).pipe(
        takeUntil(this.destroyed$),
        tap((accounts: any) => {
          if (accounts) {

            let i = 1;
            for (let account of accounts) {
              account.num = i++;
              let fullAddress = ''
              for (const [key, value] of Object.entries(account.address)) {
                if (key != '_id' && key != 'addressLine2' && value != ''){
                  fullAddress += `${value} `;
                }
              }
              account.fullAddress = fullAddress
            }

            this.accounts = accounts.filter(l => l?.location && l?.type == 'TRUCK SHOP' || l?.type == 'AUTO SHOP');
            this.cdr.detectChanges();
          }
        })
      ).subscribe();

      this.store.select(fromCoreStore.selectCoreValContacts).pipe(
        takeUntil(this.destroyed$),
        tap((statuses) => {
          if (statuses) {
            this.type = statuses.types.filter(l => l?.isCompany && (l?.name == 'TRUCK SHOP' || l?.name == 'AUTO SHOP'));
            this.cdr.detectChanges();
          }
        })
      ).subscribe();
    }

    getShops () {
      if (!this.filters?.type || !this.filters?.type.length) {
        this.filters = {
          type: ['TRUCK SHOP', 'AUTO SHOP']
        }
      }
      let filters = {
        ...this.filters,
        coordinates: JSON.stringify(this.coordinates),
      }
      filters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== '')
      );
      this.store.dispatch(new fromClaimStore.GetShops({ filters }))
    }

    setFilter(name, val) {
      if (name == 'type' && (JSON.stringify(this.filters?.type.sort()) === JSON.stringify(val.sort()))) return
      this.filters[name] = val
      this.getShops()
    }

    eventMapListener() {
      let map = this.mapElement.googleMap
        let bounds = map.getBounds();
        let sw = bounds.getSouthWest();
        let ne = bounds.getNorthEast();
        
        let left = sw.lng();
        let down = sw.lat();
        let right = ne.lng();
        let up = ne.lat();
  
        this.coordinates = {
          'lng1': left, 'lat1': down, 'lng2': right, 'lat2': up
        }

        this.getShops()
    }

    openAccountInfo(account, marker?){
      this.selectedCompany = account
      this.info.open(marker)
    }

    setPosition(loc) {
      this.location = loc
    }

    trackByFunc(index, item){
      return index;
    }

    closeClick() {
      this.currentAccount = null
      this.selectedCompany = null
    }

    onMapReady(){
      if(this.map)return
      this.map = this.mapElement.googleMap
      this.eventMapListener()
    }

    close() {
      this.coreStore.dispatch(new CloseModal());
    }

    ngOnDestroy() {
      this.store.dispatch(new fromClaimStore.GetShopsSuccess([]))
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
}